<template>
  <v-card>
    <v-progress-linear
      :active="loading"
      color="blue"
      height="4"
      indeterminate
    />
    <v-card-text>
      <div class="row radio">
        <div class="col-6 right-border">
          <b>{{ $t('system.was_conversation_with_manager') }}</b>
          <v-radio-group
            class="conversation-radio mt-0"
            :value="clientWantsMakeOrder"
            readonly
            dense
            row
          >
            <v-radio
              :value="true"
              color="success"
              :label="$t('system.yes')"
            />
            <v-radio
              :value="false"
              color="error"
              :label="$t('system.no')"
            />
          </v-radio-group>
        </div>
        <div class="col-6">
          <b>{{ $t('system.send_confirmation') }}</b>
          <v-radio-group
            class="conversation-radio mt-0"
            :value="order.sendConfirmation"
            readonly
            dense
            row
          >
            <v-radio
              :value="true"
              color="success"
              :label="$t('system.yes')"
            />
            <v-radio
              :value="false"
              color="error"
              :label="$t('system.no')"
            />
          </v-radio-group>
        </div>
      </div>
      <h6 class="mt-3">{{ $t('system.total') }}: {{ order.totalValue || 0 }}{{ order.currency }}</h6>
      <v-data-table
        :headers="positionHeaders"
        :items="order.positions"
      />
    </v-card-text>
  </v-card>
</template>

<script>

// TODO: implement order positions

import axios from 'axios'
import notification from "@/notification/notify";

export default {
  name: 'OrderStep',
  props: {
    visitId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    loading: false,
    positionHeaders: [
      {
        text: 'system.name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'system.item_price',
        sortable: true,
        value: 'item_price',
      },
      {
        text: 'system.quantity',
        sortable: true,
        value: 'quantity',
      },
      {
        text: 'system.items_price',
        sortable: true,
        value: 'items_price',
      },
    ],
    clientWantsMakeOrder: null,
    order: {
      sendConfirmation: null,
      totalValue: null,
      currency: null,
      note: ''
    },
    positions: []
  }),
  beforeMount() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const {data} = await axios.get(`sk_visits/${this.visitId}/order`)
        const order = {data}
        this.clientWantsMakeOrder = !!order.client_wants_order
        this.order = {
          sendConfirmation: !!order.send_confirmation,
          totalValue: order.total_value,
          currency: order.total_currency,
          note: order.note
        }
        this.positions = order.positions
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.radio {
  label {
    margin-bottom: 0 !important;
  }
}
</style>
